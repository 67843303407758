<template>
	<EditorForm :id="id" :is-update="isUpdate" @submit="updateEditor" @cancel="gotoList" />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_EDITOR, UPDATE_EDITOR } from "../store/action-types"
import { RESET_STATE_EDITOR } from "../store/mutation-types"
import { RESET_STATE_PAYSHEET } from "../../paysheet/store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("editors")
const { mapMutations: paysheetMutations } = createNamespacedHelpers("paysheet")
export default {
	name: "UpdateEditor",
	components: {
		EditorForm: () => import("../componetns/EditorForm"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.getEditorDetail()
	},
	beforeDestroy() {
		this.RESET_STATE_EDITOR()
		this.RESET_STATE_PAYSHEET()
	},
	methods: {
		...mapActions({ GET_EDITOR, UPDATE_EDITOR }),
		...mapMutations({ RESET_STATE_EDITOR }),
		...paysheetMutations({ RESET_STATE_PAYSHEET }),
		gotoList() {
			this.$router.go(-1)
		},
		async updateEditor(params) {
			await this.UPDATE_EDITOR(params)
		},
		async getEditorDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_EDITOR(params)
		},
	},
}
</script>

<style></style>
